import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {
  pass:any;
  usuario:any = '';
  autorizacao:any = '';

  constructor(private http:HttpClient, private route:Router) {}

  login(user){
    this.http.get('https://api-prosp.herokuapp.com/usuarios?filtro={"email":"'+user.email+'"}').toPromise()
    .then( res => {
        if (res == ''){
          alert("USUARIO NÃO ENCONTRADO!")
          return;
        } else{
          this.pass = res[0].senha;
          this.autorizacao = res[0].centralMenu;
       if(this.pass == user.password){
         let storage = Math.random().toString(36).slice(-8);
         localStorage.setItem('tokenProsperity', JSON.stringify(storage));
         this.usuario = res[0].nome;
         localStorage.setItem('usuario', this.usuario);
         if(this.autorizacao){
          this.isLoggedIn();
          this.route.navigate(['/inicio']);
          location.reload()
         }else{
           alert("Você não tem permissão para acessar, por favor contate o Administrador!")
           this.logOut();
         }
      } else{
        alert("SENHA INCORRETA!")
        return;
      }}
    },
      err => {
        if(err.status < 200){
          alert("Por Favor Verifique Sua Conexão com a Internet")
        }
      }
    ).catch(err => console.log(err))
}

  logOut() {
    localStorage.clear();
    this.isLoggedIn();
    this.route.navigate(['/login']);
  }

  isLoggedIn(): boolean {
    var token: String = this.getToken();
    return token && token.length > 0;
}

getToken(): String {
  var token = localStorage.getItem('tokenProsperity');
  return token ? token : "";
}

}   