import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})

export class InicioComponent {
  dataInicio:any;
  dataFim:any;
  nomeCliente:any;
  titulo:any;
  dataInicioVencimento:any;
  dataFimVencimento:any;
  valor:any;
  cpfCliente:any;
  relatorio:any[] = [];
  cadastro:any = [];
  contasReceber:any = [];
  passivo:number = 0;
  totalValor:number = 0;
  loaderRequest:boolean = false;
  pathURL:string = 'https://www.prosperitycestasbasicas.com.br/uploads/';
  imgRG1:any = "";
  imgRG2:any = "";
  imgCNH:any = "";
  imgCTPS:any = "";
  imgAVALISTA1:any = "";
  imgAVALISTA2:any = "";
  imgRECIBO:any = "";
  imgOUTROS:any = "";
  observacoes:any = "";
  showCardsHistorico:boolean = false;
  showDocumentosCliente:boolean = false;
  historicosEndereco:any = [];
  historicos:any = [];
  historicosReagendamentos:any = [];
  historicosPagamentos:any = [];
  historicosVenda:any = [];
  historicosPosVenda:any = [];
  historicosCobranca:any = [];
  historicosCentralCobranca:any = [];
  historicosCobrancaVendedor:any = [];
  historicosPassivoZero:any = [];
  urlHistorico:any = "https://api-prosp.herokuapp.com/historicos"
  constructor(private http: HttpClient,public _router: Router, public afs: AuthService){}

  buscarClienteData(dataInicio,dataFim){
    this.loaderRequest = true;
    this.http.get('https://api-prosp.herokuapp.com/contas-receber?filtro={"regiaoCliente":"CENTRAL COBRANÇAS", "dataInclusao":{"$gte":"'+dataInicio+'","$lte":"'+dataFim+'"}}&sort={"cliente":1}').subscribe((data:any) => {
      this.relatorio = data;
      this.totalValor = 0;
      this.relatorio.forEach(function(item){
        this.totalValor += item.valor
      }.bind(this))
      this.loaderRequest = false;
    })
  }

  buscarNomeCliente(nomeCliente){
    this.loaderRequest = true;
  this.http.get('https://api-prosp.herokuapp.com/contas-receber?filtro={"regiaoCliente":"CENTRAL COBRANÇAS", "cliente":{"$regex":"'+nomeCliente+'","$options":"i"}}&sort={"cliente":1}').subscribe((data:any) => {
      this.relatorio = data;
      this.totalValor = 0;
      this.relatorio.forEach(function(item){
        this.totalValor += item.valor
      }.bind(this))
      this.loaderRequest = false;
    })
  }

  buscarTitulo(titulo){
    this.loaderRequest = true;
    this.http.get('https://api-prosp.herokuapp.com/contas-receber?filtro={"regiaoCliente":"CENTRAL COBRANÇAS", "titulo":'+titulo+'}&sort={"cliente":1}').subscribe((data:any) => {
        this.relatorio = data;
        this.totalValor = 0;
        this.relatorio.forEach(function(item){
          this.totalValor += item.valor
        }.bind(this))
        this.loaderRequest = false;
      })
    }

  buscarVencimento(dataInicioVencimento,dataFimVencimento){
    this.loaderRequest = true;
    this.http.get('https://api-prosp.herokuapp.com/contas-receber?filtro={"regiaoCliente":"CENTRAL COBRANÇAS", "vencimento":{"$gte":"'+dataInicioVencimento+'","$lte":"'+dataFimVencimento+'"}}&sort={"cliente":1}').subscribe((data:any) => {
      this.relatorio = data;
      this.totalValor = 0;
      this.relatorio.forEach(function(item){
        this.totalValor += item.valor
      }.bind(this))
      this.loaderRequest = false;
    })
  }

  buscarCPFCliente(cpfCliente){
    this.loaderRequest = true;
    this.http.get('https://api-prosp.herokuapp.com/contas-receber?filtro={"regiaoCliente":"CENTRAL COBRANÇAS", "cpfCliente":{"$regex":"'+cpfCliente+'","$options":"i"}}&sort={"cliente":1}').subscribe((data:any) => {
        this.relatorio = data;
        this.totalValor = 0;
        this.relatorio.forEach(function(item){
          this.totalValor += item.valor
        }.bind(this))
        this.loaderRequest = false;
      })
    }

  buscarValor(valor){
    this.loaderRequest = true;
    this.http.get('https://api-prosp.herokuapp.com/contas-receber?filtro={"regiaoCliente":"CENTRAL COBRANÇAS", "valor":'+valor+'}&sort={"cliente":1}').subscribe((data:any) => {
        this.relatorio = data;
        this.totalValor = 0;
        this.relatorio.forEach(function(item){
          this.totalValor += item.valor
        }.bind(this))
        this.loaderRequest = false;
      })
    }

    detalhesCliente(id){
      this.http.get('https://api-prosp.herokuapp.com/clientes/'+id).subscribe((data: any) => {
        this.cadastro = data;
        this.imgRG1 = this.pathURL+this.cadastro.codigoCliente+'/RG1/'+this.cadastro.codigoCliente+'.jpeg'
        this.imgRG2 = this.pathURL+this.cadastro.codigoCliente+'/RG2/'+this.cadastro.codigoCliente+'.jpeg'
        this.imgCNH = this.pathURL+this.cadastro.codigoCliente+'/CNH/'+this.cadastro.codigoCliente+'.jpeg'
        this.imgCTPS = this.pathURL+this.cadastro.codigoCliente+'/CTPS/'+this.cadastro.codigoCliente+'.jpeg'
        this.imgAVALISTA1 = this.pathURL+this.cadastro.codigoCliente+'/AVALISTA1/'+this.cadastro.codigoCliente+'.jpeg'
        this.imgAVALISTA2 = this.pathURL+this.cadastro.codigoCliente+'/AVALISTA2/'+this.cadastro.codigoCliente+'.jpeg'
        this.imgRECIBO = this.pathURL+this.cadastro.codigoCliente+'/RECIBO/'+this.cadastro.codigoCliente+'.jpeg'
        this.imgOUTROS = this.pathURL+this.cadastro.codigoCliente+'/OUTROS/'+this.cadastro.codigoCliente+'.jpeg'
        this.http.get('https://api-prosp.herokuapp.com/contas-receber?filtro={"codigoCliente":'+this.cadastro.codigoCliente+',"cpfCliente":{"$regex":"'+this.cadastro.cpf+'","$options":"i"}}').subscribe((data:any[]) => {
          this.contasReceber = data;
            let totalSoma = 0;
          this.contasReceber.forEach(function(item){
              totalSoma += item.valor;
            });
            this.passivo = totalSoma;
        });   
        this.http.get('https://api-prosp.herokuapp.com/historicos?filtro={"codigoCliente":'+this.cadastro.codigoCliente+',"motivo":"Observação"}').subscribe((data: any) => {
          this.observacoes = data});
        });
    let modal = document.getElementById('modalDetails');
    modal.style.display = "block"
  }

  closeModal(){
    let modal = document.getElementById('modalDetails');
    modal.style.display = "none";
    this.passivo = 0;
    this.contasReceber = [];
    this.cadastro = [];
    this.showCardsHistorico = false;
    this.showDocumentosCliente = false;
  }

  showHistoricos(){
    this.http.get(this.urlHistorico+'?filtro={"codigoCliente":'+this.cadastro.codigoCliente+'}&sort={"data":1, "hora":1}').subscribe((data: any) => {
      this.historicos = data});
    this.http.get(this.urlHistorico+'?filtro={"codigoCliente":'+this.cadastro.codigoCliente+',"motivo":{ "$in": ["Mudança Endereço/Região", "Mudança Dados Pessoais", "Mudança Telefone"]}}&sort={"data":1, "hora":1}').subscribe((data: any) => {
      this.historicosEndereco = data});
    this.http.get(this.urlHistorico+'?filtro={"codigoCliente":'+this.cadastro.codigoCliente+',"motivo":"Reagendamento"}&sort={"data":1, "hora":1}').subscribe((data: any) => {
      this.historicosReagendamentos = data});
    this.http.get(this.urlHistorico+'?filtro={"codigoCliente":'+this.cadastro.codigoCliente+',"motivo":{ "$in": ["Pagamento Integral", "Pagamento Parcial"]}}&sort={"data":1, "hora":1}').subscribe((data: any) => {
      this.historicosPagamentos = data});
    this.http.get(this.urlHistorico+'?filtro={"codigoCliente":'+this.cadastro.codigoCliente+',"motivo":"Venda"}&sort={"data":1, "hora":1}').subscribe((data: any) => {
      this.historicosVenda = data});
    this.http.get(this.urlHistorico+'?filtro={"codigoCliente":'+this.cadastro.codigoCliente+',"motivo":"Pós Venda"}&sort={"data":1, "hora":1}').subscribe((data: any) => {
      this.historicosPosVenda = data});
    this.http.get(this.urlHistorico+'?filtro={"codigoCliente":'+this.cadastro.codigoCliente+',"motivo":"Cobrança"}&sort={"data":1, "hora":1}').subscribe((data: any) => {
      this.historicosCobranca = data});
    this.http.get(this.urlHistorico+'?filtro={"codigoCliente":'+this.cadastro.codigoCliente+',"motivo":"Central Cobrança"}&sort={"data":1, "hora":1}').subscribe((data: any) => {
      this.historicosCentralCobranca = data});
    this.http.get(this.urlHistorico+'?filtro={"codigoCliente":'+this.cadastro.codigoCliente+',"motivo":"Cobrança Vendedor"}&sort={"data":1, "hora":1}').subscribe((data: any) => {
      this.historicosCobrancaVendedor = data});
    this.http.get(this.urlHistorico+'?filtro={"codigoCliente":'+this.cadastro.codigoCliente+',"motivo":"Passivo Zero"}&sort={"data":1, "hora":1}').subscribe((data: any) => {
      this.historicosPassivoZero = data});
   if (this.showCardsHistorico == false){
    this.showCardsHistorico = true;
    this.showDocumentosCliente = false;
   }else {
    this.showCardsHistorico = false;
    this.showDocumentosCliente = false;
   }
}

showDocumentos(){
 if (this.showDocumentosCliente == false){
  this.imgRG1 = this.pathURL+this.cadastro.codigoCliente+'/RG1/'+this.cadastro.codigoCliente+'.jpeg'
  this.imgRG2 = this.pathURL+this.cadastro.codigoCliente+'/RG2/'+this.cadastro.codigoCliente+'.jpeg'
  this.imgCNH = this.pathURL+this.cadastro.codigoCliente+'/CNH/'+this.cadastro.codigoCliente+'.jpeg'
  this.imgCTPS = this.pathURL+this.cadastro.codigoCliente+'/CTPS/'+this.cadastro.codigoCliente+'.jpeg'
  this.imgAVALISTA1 = this.pathURL+this.cadastro.codigoCliente+'/AVALISTA1/'+this.cadastro.codigoCliente+'.jpeg'
  this.imgAVALISTA2 = this.pathURL+this.cadastro.codigoCliente+'/AVALISTA2/'+this.cadastro.codigoCliente+'.jpeg'
  this.imgRECIBO = this.pathURL+this.cadastro.codigoCliente+'/RECIBO/'+this.cadastro.codigoCliente+'.jpeg'
  this.imgOUTROS = this.pathURL+this.cadastro.codigoCliente+'/OUTROS/'+this.cadastro.codigoCliente+'.jpeg'
  this.showDocumentosCliente = true;
  this.showCardsHistorico = false;
 }else {
  this.showDocumentosCliente = false;
  this.showCardsHistorico = false;
 }
}

}
