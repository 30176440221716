import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { User } from '../models/user';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  user: User = new User();

  constructor(public afs: AuthService, public route:Router) { }

  ngOnInit() {
    if (this.afs.isLoggedIn()){
      this.route.navigate(['inicio']);
    }
  }

}
